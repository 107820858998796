<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div class="PERInformation-from">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="期数">
          <el-select v-model="formInline.region">
            <!-- <el-option label="2022年4月 第一期" value="1"></el-option>
            <el-option label="2022年4月 第一期" value="2"></el-option>
            <el-option label="2022年4月 第一期" value="3"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="区域">
          <el-input v-model="formInline.user"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="contentList">
      <el-table
        :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        highlight-current-row
      >
        <el-table-column
          prop="date"
          align="center"
          label="昵称"
        ></el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="手机号"
        ></el-table-column>
        <el-table-column
          prop="city"
          align="center"
          label="所在区域"
        ></el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="所在区域"
        ></el-table-column>
        <el-table-column prop="tag" align="center" label="佣金金额">
          <!-- slot-scope="scope" -->
          <template>
            <span style="color: #434343; margin-right: 10px">100</span>
            <span style="color: #409eff" @click="dialogVisible = true"
              >明细</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="total"
      ></paging-fy>
    </div>

    <!-- 弹出层 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :width="ks ? '90%' : '700px'"
      :before-close="handleClose"
      title="推荐佣金明细"
    >
      <div class="contentList">
        <el-table
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 20px"
          highlight-current-row
        >
          <el-table-column
            prop="date"
            align="center"
            label="昵称"
          ></el-table-column>
          <el-table-column
            prop="name"
            align="center"
            label="手机号"
          ></el-table-column>
          <el-table-column
            prop="city"
            align="center"
            label="所在区域"
          ></el-table-column>
          <el-table-column
            prop="address"
            align="center"
            label="所在区域"
          ></el-table-column>
          <el-table-column prop="tag" align="center" label="佣金金额">
            <!-- slot-scope="scope" -->
            <template>
              <span style="color: #434343; margin-right: 10px">100</span>
              <span style="color: #409eff">明细</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage3"
          :total="total"
        ></paging-fy>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 1,
      nav: {
        firstNav: "团长管理",
        secondNav: "任务排行",
      },
      dialogVisible: false,
      formInline: {
        user: "",
        region: "",
      },
      currentPage: 1,
      currentPage3: 1,
      tableData: [

      ],
      ks: "",
    };
  },
  mounted() {
    this.ks = window.screen.width < 768;
  },
  methods: {
    changeCurrentPage(val) {
      this.currentPage = val;
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style lang="less">
.PERInformation-from > *:not(:last-child) {
  margin-right: 1rem;
}
.block {
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: center;
}
.block1 {
  height: 50px;
  display: flex;
  align-items: center;
  @media screen and(max-width: 768px) {
    overflow: scroll;
  }
}
.el-pagination__jump {
  margin-right: 20px;
}
</style>
